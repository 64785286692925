.modal {
  padding: 0;
  margin: 0;
  background-color: #f3f3f3;
  color: #434343;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  > .header {
    width: 100%;
    font-size: 18px;
    text-align: left;
    background-color: #ffd800;
    padding: 10px 30px;
    color: #434343;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
  }
  > .content {
    width: 100%;
    padding: 10px 30px;
    .month-picker > .rmp-container .rmp-popup {
      z-index: 999;
    }

    label {
      padding: 15px 0;
      display: inline-block;
    }
    > input,
    .modal-input {
      height: 40px;
      width: 100%;
      padding: 0 20px;
      border: 1px solid #cfdbde;
      border-radius: 4px;
      background-color: #ffffff;
      display: block;
    }
    .column-50 {
      width: 50%;
      display: inline-block;
      .column-content.first {
        padding-right: 10px;
      }
      .column-content.last {
        padding-left: 10px;
      }
    }
  }
  > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: right;
    border: none;
    margin-top: 10px;
    display: block;
    button {
      border: 0;
      border-radius: 6px;
      background-color: #ffd800;
      padding: 10px;
      margin-right: 25px;
      outline: none;
      &.delete {
        background-color: transparent;
        color: #ff6060;
        float: left;
        margin-left: 20px;
      }
      &.close {
        background-color: transparent;
        color: 777777;
      }
      cursor: pointer;
    }
  }
  > .close {
    &:focus {
      outline: none;
    }
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: 5px;
    top: 5px;
    font-size: 24px;
    background: transparent;
    border: 0;
  }
}
.popup-content {
  padding: 0;
}
.popup-overlay {
  overflow: auto;
}
