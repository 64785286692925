@charset "UTF-8";

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Bold.ttf');
  font-weight: 800;
  font-style: normal;
}

html {
  box-sizing: border-box;
  font-size: 15px;
  @media screen and (max-width: 1280px) {
    font-size: 14px;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: Roboto;
  background-color: #f0f0f0;
  overflow-y: hidden;
}
