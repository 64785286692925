.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    background: #8e8e8e;
    border-radius: 8px 8px 0 0;
    margin: 0 2px;
    color: #2b2b2b;

    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;

    &--selected {
      background: #f3f3f3;
      border: 0;
      color: black;
      border-radius: 8px 8px 0 0;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      box-shadow: 0 0 5px hsl(208, 99%, 50%);
      border-color: hsl(208, 99%, 50%);
      outline: none;

      &:after {
        content: '';
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: red;
      }
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
